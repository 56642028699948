import React, { useEffect, useState, useRef } from "react";
import "./NewsSection.css";

const NewsSection = ({ newsData }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [slidesToShow, setSlidesToShow] = useState(window.innerWidth >= 769 ? 3 : 1);
  const [isAutoplay, setIsAutoplay] = useState(window.innerWidth >= 769);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 769);
  const sliderRef = useRef(null);

  useEffect(() => {
    let interval;
    if (isAutoplay) {
      interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % newsData.length);
      }, 3000);
    }
    return () => clearInterval(interval);
  }, [isAutoplay, newsData.length]);

  // Update state on resize
  const handleResize = () => {
    const newSlidesToShow = window.innerWidth >= 769 ? 3 : 1;
    setSlidesToShow(newSlidesToShow);
    setIsMobile(window.innerWidth < 769);
    setIsAutoplay(window.innerWidth >= 769);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleCardClick = (url) => {
    if (url) {
      window.open(url, "_blank");
    }
  };

  const handleTouchStart = (e) => {
    touchStartX = e.touches[0].clientX;
  };

  const handleTouchEnd = (e) => {
    const touchEndX = e.changedTouches[0].clientX;
    if (isMobile) {
      if (touchStartX - touchEndX > 50) {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % newsData.length);
      } else if (touchEndX - touchStartX > 50) {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + newsData.length) % newsData.length);
      }
    }
  };

  let touchStartX = 0;

  const handleSliderChange = (e) => {
    setCurrentIndex(Number(e.target.value));
  };

  return (
    <div className="news-section" role="region" aria-labelledby="latest-news-heading">
      <h2 id="latest-news-heading">Latest News</h2>
      <div
        className="slider"
        ref={sliderRef}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        aria-live="polite"
      >
        <div
          className="slider-track"
          style={{
            transform: `translateX(-${(currentIndex * 100) / slidesToShow}%)`,
            transition: "transform 0.5s ease",
          }}
          role="list"
          aria-roledescription="carousel"
        >
          {newsData.map((news, index) => (
            <div
              className="slide"
              key={index}
              style={{
                flex: `0 0 ${100 / slidesToShow}%`,
              }}
              role="listitem"
              aria-label={`Slide ${index + 1}: ${news.title}`}
            >
              <div
                className="news-card"
                onClick={() => handleCardClick(news.link)}
                role="button"
                aria-label={`Open news article: ${news.title}`}
                tabIndex={0} // Allow keyboard focus
              >
                <img
                  src={news.image}
                  alt={news.title}
                  aria-describedby={`news-${index}`}
                />
                <h3 id={`news-${index}`}>{news.title}</h3>
                <p>{news.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {!isMobile && (
        <div className="slider-bar-dots" aria-label="Slide navigation">
          {newsData.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === currentIndex ? "active" : ""}`}
              onClick={() => setCurrentIndex(index)}
              tabIndex={0}
              role="button"
              aria-label={`Go to slide ${index + 1}`}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  setCurrentIndex(index);
                }
              }}
            ></span>
          ))}
        </div>
      )}

      {/* Add navigation buttons for better keyboard accessibility */}
      {!isMobile && (
        <div className="navigation-buttons">
          <button
            aria-label="Previous slide"
            onClick={() =>
              setCurrentIndex((prevIndex) => (prevIndex - 1 + newsData.length) % newsData.length)
            }
            className="prev-slide-button"
          >
            Previous
          </button>
          <button
            aria-label="Next slide"
            onClick={() =>
              setCurrentIndex((prevIndex) => (prevIndex + 1) % newsData.length)
            }
            className="next-slide-button"
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default NewsSection;
