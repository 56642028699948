import React from "react";

const reviews = [
  {
    id: 1,
    review:
      "This app is simply incredible. I would give it more than 5 stars if I could. After it described a short video of my daughter sliding down a slide at the fair, I purchased the premium membership. Just the description of that one video was worth it to me.",
    stars: 5,
    user: "Jim Robertson",
  },
  {
    id: 2,
    review:
      "There is little to say about this app. This has proven to be a boon for us blind people. I am feeling very happy to get today what I have been waiting for years. All of us blind people are using it with great pleasure. We would like to thank the developer very much for bringing this app to all of us.",
    stars: 5,
    user: "Jayaditya Thakur",
  },
  {
    id: 3,
    review:
      "This app literally changed my life as a blind individual. Its video description feature greatly helps me navigate my daily life as well as puts me on the same level as my sighted peers by being able to share videos to the app and have them explained so I could understand what's going on. Thank you so much.",
    stars: 5,
    user: "Alice Johnson",
  },
];

const ReviewCard = ({ review, stars, user }) => {
  return (
    <div style={styles.card} role="article" aria-labelledby={`review-${user.replace(" ", "-")}`}>
      <div style={styles.stars} aria-label={`Rating: ${stars} stars`}>
        {"⭐".repeat(stars)}
      </div>
      <p style={styles.review} aria-describedby={`review-description-${user.replace(" ", "-")}`}>
        {review}
      </p>
      <p style={styles.user} id={`review-description-${user.replace(" ", "-")}`}>
        <strong>{user}</strong>
      </p>
    </div>
  );
};

const Section8 = () => {
  return (
    <section id="User-Review" style={styles.section} role="region" aria-labelledby="user-reviews-heading">
      <h2 id="user-reviews-heading" style={styles.heading}>
        User Reviews
      </h2>
      <div style={styles.container} role="list">
        {reviews.map((item) => (
          <ReviewCard
            key={item.id}
            review={item.review}
            stars={item.stars}
            user={item.user}
            aria-labelledby={`review-${item.user.replace(" ", "-")}`}
          />
        ))}
      </div>
    </section>
  );
};

const styles = {
  section: {
    padding: "60px 20px",
    background: "linear-gradient(135deg, #1d3c56, #333e48)",
    textAlign: "center",
    position: "relative",
    zIndex: 1,
    minHeight: "auto",
  },
  heading: {
    fontSize: "28px",
    color: "#ffffff",
    marginBottom: "40px",
    lineHeight: "1.2",
    '@media (max-width: 768px)': {
      fontSize: "24px",
      marginBottom: "30px",
    },
    '@media (max-width: 480px)': {
      fontSize: "20px",
      marginBottom: "20px",
    },
  },
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "30px",
    '@media (max-width: 768px)': {
      flexDirection: "column",
      gap: "20px",
    },
  },
  card: {
    width: "400px",
    background: "linear-gradient(135deg, #2c3e50, #1a1a1a)",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    padding: "30px",
    textAlign: "center",
    fontFamily: "Arial, sans-serif",
    minHeight: "350px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    '@media (max-width: 768px)': {
      width: "100%",
      minHeight: "auto",
    },
    '@media (max-width: 480px)': {
      padding: "20px",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    },
  },
  stars: {
    fontSize: "24px",
    marginBottom: "15px",
    '@media (max-width: 768px)': {
      fontSize: "20px",
    },
    '@media (max-width: 480px)': {
      fontSize: "16px",
    },
  },
  review: {
    fontSize: "16px",
    color: "#ffffff",
    lineHeight: "1.5",
    '@media (max-width: 768px)': {
      fontSize: "14px",
    },
    '@media (max-width: 480px)': {
      fontSize: "12px",
    },
  },
  user: {
    fontSize: "16px",
    color: "#ffffff",
    fontWeight: "bold",
    marginTop: "20px",
    '@media (max-width: 768px)': {
      fontSize: "14px",
    },
    '@media (max-width: 480px)': {
      fontSize: "12px",
    },
  },
};

export default Section8;
