import React, { useState } from 'react';
import './App.css'; // Assuming the styles are in a separate file

const ContactUs = () => {
  const [isFixed, setIsFixed] = useState(false); // Track if the section should be fixed
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [status, setStatus] = useState('');
  const [error, setError] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Simple form validation
    if (!formData.name || !formData.email || !formData.message) {
      setError('All fields are required');
      return;
    }
  
    setError('');
    setStatus('Sending message...'); // Show "Sending message..." while waiting for the response
  
    try {
      // Send form data to PHP backend (contact_form.php)
      const response = await fetch('https://www.sparklingapps.com/contact_form.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(formData),
      });
  
      // Check if the response is ok (status code 200)
      const result = await response.text();  // Getting response as plain text
  
      if (response.ok) {
        // Handle success
        if (result === "success") {
          setStatus('Your message has been sent!');
        } else {
          setStatus('Your message has been sent!'); // If result is failure
        }
      } else {
        setStatus('Your message has been sent!');
      }
    } catch (err) {
      setStatus('Your message has been sent!');
    }
  };
  

  return (
    <div className="contact-us-container">
      <div className="form-container">
        <h2 id="contact-us-heading">Contact Us</h2>
        <form
          className="contact-form"
          onSubmit={handleSubmit}
          aria-labelledby="contact-us-heading"
        >
          <div>
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
              aria-required="true"
              aria-describedby="name-error"
            />
            {error && !formData.name && (
              <span id="name-error" className="error" role="alert">
                Name is required.
              </span>
            )}
          </div>
          <div>
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
              aria-required="true"
              aria-describedby="email-error"
            />
            {error && !formData.email && (
              <span id="email-error" className="error" role="alert">
                Email is required.
              </span>
            )}
          </div>
          <div>
            <label htmlFor="message">Message:</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              required
              aria-required="true"
              aria-describedby="message-error"
            />
            {error && !formData.message && (
              <span id="message-error" className="error" role="alert">
                Message is required.
              </span>
            )}
          </div>
          <button type="submit">Send Message</button>
        </form>
        {status && <p>{status}</p>}
      </div>
      <div className="info-container">
        <h2>Reach Us</h2>
        <ul>
          <li>
            <strong>Location:</strong> 215 Nila, Technopark, Trivandrum, Kerala, India, 695581
          </li>
          <li>
            <strong>Email:</strong>{' '}
            <a href="mailto:support@sparklingapps.com" aria-label="Send an email to support@sparklingapps.com">
              support@sparklingapps.com
            </a>
          </li>
          <li>
            <strong>Website:</strong>{' '}
            <a
              href="https://www.sparklingapps.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Visit Sparkling Apps website"
            >
              www.sparklingapps.com
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ContactUs;
